.transparent {
  background-color:transparent;
}
.float {
  animation: floating 5s linear infinite;
  margin: 6px;
  padding: 2px;
  background: #5c6d71;
  color: #ffffff;
  box-shadow: -20px -17px 8px 0px #44464763;
}
@keyframes floating {
  0% {
      transform: translate(0%, 20%);
  }
  50% {
      transform: translate(0%, -20%);
  }
  100% {
      transform: translate(0%, 20%);
  }
}

nav {
  position: sticky;
  position: fixed;
  top: 0;
  width: 100%;
  
}

nav.scrolled {
  background: #e4e3e3;
}

.flip-card {
  background-color: transparent;
  width: 550px;
  height: 300px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #fff;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}