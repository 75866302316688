body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animation-div {
  margin: 0;
  padding: 0;
  animation: colorchange infinite;
  animation-name: color_change;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes color_change {
  10% {
    background-color: rgb(244, 80, 80);
  }
  20% {
    background-color: rgb(212, 119, 255);
  }
  30% {
    background-color: rgb(125, 255, 132);
  }
  40% {
    background-color: rgb(122, 224, 255);
  }
  50% {
    background-color: rgb(140, 140, 255);
  }
  60% {
    background-color: rgb(135, 6, 138);
  }
  70% {
    background-color: rgb(152, 255, 149);
  }
  80% {
    background-color: rgb(254, 173, 125);
  }
  100% {
    background-color: rgb(255, 137, 206);
  }
}
